/**
 * Clear a string from tags
 * @param {String} htmlStr - string with html tags
 * @returns {String} string with plain text
 */
export default function (htmlStr) {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = htmlStr;
  let res = tmp.textContent || tmp.innerText || '';
  res.replace('\u200B', ''); // zero width space
  res = res.trim();
  return res;
}
