<template>
  <div class="promise-payment">
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" />
      <base-empty v-else-if="isEmpty" :title="$t('empty.title')" class="vps__empty" />
      <main-card v-else-if="config && formData" class="promise-payment__card">
        <div v-if="isViewFault" class="promise-payment__form">
          <div class="promise-payment__text">
            <label class="standart-text error-color">{{ $t('hardcode.alarm') }}</label>
          </div>
        </div>
        <div class="promise-payment__form">
          <div
            v-if="fields && fields.info && fields.info.label"
            class="promise-payment__text standart-text"
          >
            <component :is="item.component" v-for="(item, idx) in infoMsg" :key="idx">
              <template v-if="item.text">
                {{ item.text }}
              </template>
              <li
                v-for="(text, index) in item.list"
                v-else-if="item.list && item.list.length"
                :key="index"
              >
                {{ text }}
              </li>
            </component>
            <div class="promise-payment__list-head medium-title">{{ $t('hardcode.text1') }}</div>
            <div class="promise-payment__text">{{ $t('hardcode.text2') }}</div>
            <div class="promise-payment__text">{{ $t('hardcode.text3') }}</div>
            <div v-if="provider !== '3'" class="promise-payment__text">
              {{ $t('hardcode.text4') }}
            </div>
          </div>
          <div v-for="field in listComponents" :key="field.name" class="promise-payment__list">
            <div v-if="field.label || field.selectAll" class="promise-payment__list-head">
              <div v-if="field.label" class="promise-payment__list-label medium-title">
                {{ field.label }}
              </div>
              <div v-if="field.selectAll" class="promise-payment__list-selectall">
                <base-checkbox
                  :checked="selectAll[field.name].value"
                  @change="onSelectAllChange(field.name, $event)"
                >
                  {{ $t('selectAll') }}
                </base-checkbox>
              </div>
            </div>
            <div class="promise-payment__list-options">
              <div v-for="opt in field.options" :key="opt.id" class="promise-payment__list-option">
                <base-checkbox
                  :value="formData[opt.formDataName]"
                  :true-value="trueValue"
                  :false-value="falseValue"
                  :name="opt.formDataName"
                  :hint="opt.checkbox.hint"
                  :readonly="opt.checkbox.readonly"
                  class="promise-payment__list-checkbox"
                  @change="onFieldChange(opt.formDataName, $event, field)"
                >
                  {{ opt.name }}
                </base-checkbox>
                <div class="promise-payment__list-info standart-text">
                  <div
                    v-if="opt.expire"
                    :class="`${opt.expireColor}-color`"
                    class="promise-payment__list-expire"
                  >
                    {{ opt.expire }}
                  </div>
                  <div class="promise-payment__list-delete small-text note-color">
                    {{ opt.delete }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template #footerEnd>
          <!--          <base-button :disabled="!hasChanges || false" :loading="isSending" @click="send()">-->
          <base-button
            :disabled="!hasChanges ? !isViewFault : !hasChanges || false"
            :loading="isSending"
            @click="send()"
          >
            {{ $t('submit') }}
          </base-button>
        </template>
      </main-card>
    </transition>
  </div>
</template>

<script>
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox.vue';
import sanitize from '@/utils/sanitize';
import billMgrActions from '@/mixins/billmgr/billMgrToolActions';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import qs from 'qs';
export default {
  name: 'PromisePayment',
  components: {
    BaseCheckbox,
    BaseEmpty,
  },
  mixins: [billMgrActions],
  data() {
    return {
      func: 'promisepayment.add',
      config: null,
      isLoading: false,
      isFalse: false,
      isSending: false,
      formData: null,
      formDataBackup: null,
      selectAll: null,
      trueValue: 'on',
      falseValue: 'off',
      isEmpty: false,
    };
  },
  computed: {
    fields() {
      if (!this.config) return null;
      return this.config.customfields;
    },
    lists() {
      if (!this.config) return null;
      return this.config.list;
    },
    infoMsg() {
      if (!this.fields || !this.fields.info || !this.fields.info.label) return [];
      const splitted = this.fields.info.label.split('<br/>');
      const list = splitted.reduce((acc, item, idx) => {
        if (item[0] === '-') {
          if (acc[idx - 1].component !== 'ul') {
            acc.push({ component: 'ul', list: [item.substr(1)] });
          } else {
            acc[idx - 1].list.push(item.substr(1));
          }
        } else acc.push({ component: 'p', text: item });
        return acc;
      }, []);
      return list.splice(0, 2); // хардкод   - убираем хвост массива с текстом по обещанному платежу, т.к. в билинге это не нашли
    },
    listComponents() {
      if (!this.lists) return [];
      return Object.keys(this.lists).map(k => {
        const listField = this.fields[k];
        const labelK = k.replace('_list', '');
        listField.plid = labelK.replace('itemtype_', '');
        const label =
          this.fields[labelK] && this.fields[labelK].label ? this.fields[labelK].label : '';
        if (!listField.label) listField.label = sanitize(label);
        listField.options = this.lists[k]
          .filter(item => !!item.expire)
          .map(item => {
            const inner = { ...item };
            // console.log(item);
            // parse value from text, waiting for normal api
            const value = item.info && item.info.includes(this.$t('payment.active'));
            inner.checkbox.value = value ? this.trueValue : this.falseValue;
            inner.checkbox.readonly = item.readonly === 'yes' || !!item.info;
            // parse id from text, waiting for noraml api
            // console.log(/#(\d*)\b/.exec(item.name));
            this.isFalse = !/#(\d*)\b/.exec(item.name);
            // console.log(this.isFalse);
            inner.id = /#(\d*)\b/.exec(item.name) ? /#(\d*)\b/.exec(item.name)[1] : 1;
            // inner.id = /#(\d*)\b/.exec(item.name)[1];
            // make name from aside datas, waiting for normal api
            inner.formDataName = `item_${inner.id}_${listField.plid}`;
            inner.checkbox.hint = item.checkbox_hint || '';
            if (item.expire) inner.expire = sanitize(item.expire);
            // parse state from text, waiting for normal api
            if (item.expire) inner.expireColor = item.expire.includes('red') ? 'error' : 'note';
            return inner;
          });
        const selectable = listField.options.filter(item => !item.checkbox.readonly);
        if (selectable.length > 2) {
          listField.selectAll = selectable.map(item => item.formDataName);
        }
        return listField;
      });
    },
    hasChanges() {
      return !this.isObjectsEqual(this.formData, this.formDataBackup);
    },
    isViewFault() {
      return this.hasChanges && this.isFalse;
    },
    provider() {
      return this.$store.getters['moduleProviders/provider'];
    },
  },
  watch: {
    listComponents(val) {
      if (!this.formData && val.length) this.init();
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.isLoading = true;
      const params = {
        func: this.func,
      };
      this.fetchBillMgrAction(params)
        .then(data => {
          this.config = {
            customfields: data.customfields || data.fields,
            model: data.model || {},
            list: data.list || {},
            slist: data.slist || {},
            pages: data.pages || [],
          };
          if (!data.list) this.isEmpty = true;
        })
        .finally(() => (this.isLoading = false));
    },
    send() {
      const params = {
        ...this.formData,
        func: this.func,
      };
      this.isSending = true;
      this.sendBillMgrAction(params)
        .then(async data => {
          let res = data;
          if (res && res.ok && res.ok.type && res.ok.type === 'form' && res.ok.v) {
            const v = res.ok.v;
            const payload = qs.parse(v);
            res = await this.sendBillMgrAction(payload);
          }
          if (res && res.ok && res.ok === true) {
            this.showRes('success');
            this.reset();
            this.fetch();
          } else return Promise.reject(res);
        })
        .finally(() => (this.isSending = false));
    },
    reset() {
      this.config = null;
      this.formData = null;
      this.formDataBackup = null;
    },
    init() {
      this.initFormData();
      this.initSelectAll();
    },
    initFormData() {
      this.formData = this.listComponents.reduce((acc, field) => {
        field.options.forEach(option => {
          acc[option.formDataName] = option.checkbox.value;
        });
        return acc;
      }, {});
      this.formDataBackup = { ...this.formData };
    },
    initSelectAll() {
      this.selectAll = this.listComponents.reduce((acc, field) => {
        if (field.selectAll) {
          acc[field.name] = {
            list: field.selectAll,
            value: false,
          };
        }
        return acc;
      }, {});
    },
    onFieldChange(name, value, field) {
      this.formData[name] = value;
      if (field && field.selectAll) {
        const selectAllField = this.selectAll[field.name];
        if (value === this.falseValue) selectAllField.value = false;
        else if (
          value === this.trueValue &&
          selectAllField.list.every(k => this.formData[k] === this.trueValue)
        )
          selectAllField.value = true;
      }
    },
    onSelectAllChange(name, value) {
      const selectAllField = this.selectAll[name];
      selectAllField.value = value;
      selectAllField.list.forEach(key => {
        this.formData[key] = value ? this.trueValue : this.falseValue;
      });
    },
    isObjectsEqual(obj1, obj2) {
      return Object.keys(obj1).every(k => obj2[k] && obj1[k] === obj2[k]);
    },
    showRes(res) {
      this.$modals.open({
        name: 'PromisePaymentResult',
        text: this.$t(`modal.res.${res}`),
        footer: {
          centered: true,
          cancel: {
            props: { title: this.$t('ok') },
            on: {
              click: this.$modals.close,
            },
          },
        },
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "submit": "Активировать",
    "selectAll": "Выбрать все",
    "hardcode": {
      "text1": "ВАЖНО!",
      "alarm": "Произошла ошибка. Для активации обратитесь в техподдержку",
      "text2": "Отложить остановку услуг повторно возможно только после погашения выставленного счета.",
      "text3": "После активации отложенной остановки в случае неуплаты услуги будут удалены в указанную дату, ниже указана дата удаления в случае активации отложенной остановки и отсутствия оплаты до этой даты.",
      "text4": "Сама услуга проработает только 7 дней с момента активации обещанного платежа."
    },
    "empty": {
      "title": "У Вас нет услуг, которым можно подключить обещанный платёж",
      "text": ""
    },
    "payment": {
      "active": "Обещанный платеж уже активен"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.promise-payment {
  &__card {
    +breakpoint(md-and-up) {
      max-width: 40rem;
    }
  }

  &__form {
    +breakpoint(sm-and-up) {
      margin-bottom: 1.25rem;
    }
  }

  &__list {
    margin-top: 1.5rem;

    &-head {
      margin-bottom: 1.5rem;

      +breakpoint(sm-and-up) {
        flexy(space-between, center);
      }
    }

    &-selectall {
      margin-top: 0.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1rem;
      }
    }

    &-option {
      +breakpoint(sm-and-up) {
        flexy(space-between, center);
      }

      & + & {
        margin-top: 1.25rem;
      }
    }

    &-info {
      flex: 0 0 auto;

      +breakpoint(ms-and-down) {
        margin-top: 0.5rem;
      }

      +breakpoint(sm-and-up) {
        text-align: right;
        margin-left: 1rem;
      }
    }

    &-expire {
      margin-bottom: 2px;
    }
  }
}
</style>
